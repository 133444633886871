.part {
  margin-left: 17%;
}
.tabs-bar {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 10px;
}

.tab {
  flex: 1;
  padding: 10px;
  background-color: #000000;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: bold;
  color: #ffffff;
  transition: background-color 0.3s ease;
  text-align: center;
}

.tab.active {
  background-color: #db1f1f;
  color: #fff;
}

/* Tab Content */
.tabcontent {
  display: none;
}

.tabcontent.active {
  display: block;
}

#productTable {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: center;
}
td button {
  margin-right: 5px;
}

.tablerow:nth-child(even) {
  background-color: #f2f2f2;
}

/* Form Popup */
#form-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  background-color: black;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 300px;
  color: white;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.button-container button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.button-container button.add-button {
  background-color: #2196f3;
  color: #fff;
  margin-right: 10px;
}

.button-container button.cancel-button {
  background-color: #ccc;
  color: #fff;
}

.button-container button.add-button:hover,
.button-container button.cancel-button:hover {
  opacity: 0.8;
}
